import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { fakeBackendProvider } from './_helpers';
import { MomentModule } from 'ngx-moment';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { StartComponent } from './start/start.component';
import {AsideComponent} from '@/shared/components/aside/aside.component';
import {HeaderComponent} from '@/shared/components/header/header.component';
import {ModalDialogComponent} from '@/shared/components/modal-dialog/modal-dialog.component';
import {PageNotFoundComponent} from '@/shared/components/page-not-found/page-not-found.component';
// import { JwtInterceptor, ErrorInterceptor } from './_helpers';

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    AsideComponent,
    HeaderComponent,
    ModalDialogComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MomentModule.forRoot({ relativeTimeThresholdOptions: { m: 59 } })
  ],
  providers: [
    // {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    // {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
