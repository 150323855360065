/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./start.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./start.component";
var styles_StartComponent = [i0.styles];
var RenderType_StartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StartComponent, data: {} });
export { RenderType_StartComponent as RenderType_StartComponent };
export function View_StartComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0418\u0441\u0442\u043E\u0440\u0438\u044F \u0430\u0432\u0442\u043E\u0432\u043B\u0430\u0434\u0435\u043B\u044C\u0446\u0430"]))], null, null); }
export function View_StartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-start", [], null, null, null, View_StartComponent_0, RenderType_StartComponent)), i1.ɵdid(1, 114688, null, 0, i2.StartComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StartComponentNgFactory = i1.ɵccf("app-start", i2.StartComponent, View_StartComponent_Host_0, {}, {}, []);
export { StartComponentNgFactory as StartComponentNgFactory };
